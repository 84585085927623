export const menuData = {
    sms: [{ // 短信（SMS OTP）
        name: "SMS billing standard",
        url: "/sms/charging-standard",
    }, {
        name: "SMS outbound sending",
        url: "/sms/mt-sending"
    },{
        name: "SMS outbound sending_V2",
        url: "/sms/mt-sending-v2"
    }, {
        name: "SMS delivery rate reporting",
        url: "/sms/backfill-rate"
    }, {
        name: "SMS delivery receipt callback",
        url: "/sms/receipt-callback"
    }, {
        name: "SMS record query",
        url: "/sms/records-query"
    }, {
        name: "SMS record single query",
        url: "/sms/record-single-query"
    }, {
        name: "SMS reconciliation statement report",
        url: "/sms/billing-report"
    }, {
        name: "SMS Q&A",
        url: "/sms/sms-faq"
    }],
    email_code: [{ // 邮件验证码（Email OTP）
        name: "Send email verification code",
        url: "/email-code/send-code",
    }, {
        name: "Email verification code receipt callback",
        url: "/email-code/receipt-callback",
    }, {
        name: "Email verification code record query",
        url: "/email-code/records-query",
    }],
    voice1: [{ // Voice OTP
        name: "Send voice verification code",
        url: "/voice/sending-code",
    }, {
        name: "Voice verification code fill-in report",
        url: "/voice/code-backfilled",
    }],
    voice2: [{ // Voice Notification
        name: "Send voice group call",
        url: "/voice/sending-group-call",
    }, {
        name: "Send voice notification",
        url: "/voice/sending-notifications",
    }],
    voice3: [{ // voice-其他
        name: "Upload voice recording file",
        url: "/voice/upload-recording-file",
    }, {
        name: "Uploaded recording file query",
        url: "/voice/uploaded-file-query",
    }, {
        name: "Voice receipt callback",
        url: "/voice/receipt-callback",
    }, {
        name: "Voice record query",
        url: "/voice/record-query",
    }],
    wa: [{ // WhatsApp Business
        name: "Send message",
        url: "/whats-app/WhatsApp-API-mt",
    }, {
        name: "Mark inbound message as read",
        url: "/whats-app/mark-as-read",
    }, {
        name: "Upload media file",
        url: "/whats-app/upload-media",
    }, {
        name: "Get media file",
        url: "/whats-app/get-media",
    }, {
        name: "Delete media file",
        url: "/whats-app/delete-media",
    }, {
        name: "Webhook",
        url: "/whats-app/webhook",
    }],
    wa1: [{
        name: "Query number information",
        url: "/wa-gl/get-phone",
    }, {
        name: "Query message template",
        url: "/wa-gl/get-template",
    }, {
        name: "Create message template",
        url: "/wa-gl/create-template",
    }, {
        name: "Edit message template",
        url: "/wa-gl/update-template",
    }, {
        name: "Delete message template",
        url: "/wa-gl/delete-template",
    }, {
        name: "Upload template example file",
        url: "/wa-gl/upload-templatefile",
    }],
    wa2: [{
        name: "Create client application",
        url: "/wa-jcs/WhatsApp-API-createApp",
    }, {
        name: "Integrator login",
        url: "/wa-jcs/WhatsApp-API-login",
    }, {
        name: "Client application number list",
        url: "/wa-jcs/WhatsApp-API-phoneList",
    }],
    viber: [{ // Viber Business
        name: "Send message",
        url: "/viber/Viber-API-mt",
    }, {
        name: "Webhook",
        url: "/viber/Viber-webhook",
    }],
    messenger: [{ // Messenger Business
        name: "Send message",
        url: "/messenger/Messenger-API-mt",
    }, {
        name: "Webhook",
        url: "/messenger/Messenger-webhook",
    }],
    zalo: [{ // zalo Business
        name: "Send message",
        url: "/zalo/Zalo-API-mt",
    }, {
        name: "Init Journey Token",
        url: "/zalo/Init‐Journey‐Token",
    }, {
        name: "Check Journey Token",
        url: "/zalo/Check‐Journey‐Token",
    }, {
        name: "Webhook",
        url: "/zalo/Zalo-webhook",
    }],
    number_detection: [{ // 号码检测
        name: "Submit number detection",
        url: "/number-detection/Number-Check-API-submit",
    }, {
        name: "Number detection query",
        url: "/number-detection/Number-Check-API-query",
    }, {
        name: "Number detection receipt",
        url: "/number-detection/Number-Check-API-callback",
    }],
    pns: [{ // 全球号码-隐私号
        name: "Submit AB binding",
        url: "/pns/PNS-API-bind",
    }, {
        name: "Unbind AB binding",
        url: "/pns/PNS-API-unbind",
    }, {
        name: "Query counterpart number based on A or B",
        url: "/pns/PNS-API-query",
    }, {
        name: "Query AXB relationship list",
        url: "/pns/PNS-API-list",
    }, {
        name: "Webhook",
        url: "/pns/PNS-API-webhook",
    }, {
        name: "Error codes",
        url: "/pns/PNS-API-Error-Code",
    },],
    did_number: [{
        name: "Send SMS to DID number v2",
        url: "/did-number/sms-mt-v2",
    }, {
        name: "DID SMS result callback (with signature)",
        url: "/did-number/mt-receipt-callback-v2",
    }, {
        name: "DID SMS result callback (without signature)",
        url: "/did-number/mt-receipt-callback",
    }, {
        name: "Make outbound call from DID and transfer to Amazon seat",
        url: "/did-number/DID-API-Dialout-With-Amazon-Connect",
    }],
    nxlink: [{
        name: "Send message",
        url: "/nxlink/WhatsApp-API-mt",
    }, {
        name: "Webhook",
        url: "/nxlink/webhook",
    }, {
        name: "Bulk create clients",
        url: "/nxlink/bulk-create-clients",
    }, {
        name: "Query client field interface",
        url: "/nxlink/query-client-field",
    }],
    nxcc: [
        {
            name: "Embedded Client",
            url: "/nxcc/nxccIfame",
        },
        {
            name: "Manual Dial Record",
            url: "/nxcc/nxccCdr",
        },
        {
            name: "Webhook",
            url: "/nxcc/nxccWebhook",
        },
        {
            name: "Manual Dial Record Query By orderId",
            url: "/nxcc/nxccOrderIdCdr",
        },
        {
            name: "List Agent Information",
            url: "/nxcc/nxccSeatInfo",
        },
        {
            name: "List Agent Status",
            url: "/nxcc/nxccSeatStatus",
        },
        {
            name: "List Queue",
            url: "/nxcc/nxccGroupList",
        },
        {
            name: "List Agents In Queue",
            url: "/nxcc/nxccGroupChange",
        },
        {
            name: "The Binding Relationship between Agent Groups and Agents",
            url: "/nxcc/nxccGroupAgentBind",
        },
    ],
    callbot: [
        {
            name: "Callbot API Summary",
            url: "/callbot/botApiOverview",
        },
        {
            name: "Callbot API Authorization",
            url: "/callbot/botApiAuth",
        },
        {
            name: "Create Auto Dial Task",
            url: "/callbot/botCreatAutoTask",
        },
        {
            name: "Add Contact List To Task",
            url: "/callbot/botBatchAddCallList",
        },
        {
            name: "Create Task And Add Contact",
            url: "/callbot/botCreatAddTaskList",
        },
        {
            name: "Start Or Pause Task",
            url: "/callbot/botTaskOpenClose",
        },
        {
            name: "Update Task Parameters",
            url: "/callbot/botUpdateTask",
        },
        {
            name: "Get task list",
            url: "/callbot/botGetTaskList",
        },
        {
            name: "List Task Orders",
            url: "/callbot/botGetCallOrderList",
        },
        {
            name: "Stop Order",
            url: "/callbot/botStopOrderCall",
        },
        {
            name: "Get Call Detail By Order",
            url: "/callbot/botQueryOrderCall",
        },
        {
            name: "Webhook - By Call",
            url: "/callbot/botQueryCallBack",
        },
        {
            name: "Webhook - By Order",
            url: "/callbot/botQueryOrderBack",
        },
        {
            name: "Webhook - Task Status",
            url: "/callbot/botTaskStatusBack",
        },
        {
            name: "List Task",
            url: "/callbot/botCallList",
        },
        {
            name: "Export the latest batch data",
            url: "/callbot/botExportCall",
        },
        {
            name: "Import the latest batch data",
            url: "/callbot/botImportCall",
        },
        {
            name: "Ping",
            url: "/callbot/ping",
        },
    ],
    nxphone: [{ // 其他-坐席
        name: "NXphone PC User Guide",
        url: "/nxphone/pc-usage",
    }, {
        name: "NXphone Android User Guide",
        url: "/nxphone/android-usage",
    }, {
        name: "NXphone Android SDK",
        url: "/nxphone/android-sdk",
    }, {
        name: "Call hang-up reason explanation",
        url: "/nxphone/hang-up-explained",
    }, {
        name: "CDR callback interface explanation",
        url: "/nxphone/cdr-callback-v1",
    }, {
        name: "Agent API invocation interface explanation",
        url: "/nxphone/api-call-v1",
    }, {
        name: "Query CDR by orderid",
        url: "/nxphone/orderid-query-cdr",
    }, {
        name: "Agent system SIP link caller",
        url: "/nxphone/sip-call",
    }, {
        name: "Number desensitization processing",
        url: "/nxphone/number-desensitization",
    }, {
        name: "Modify phone password interface",
        url: "/nxphone/changing-interface",
    }, {
        name: "Query approval form",
        url: "/nxphone/query-approval",
    }, {
        name: "Query phone",
        url: "/nxphone/query-phone",
    }],
    short_chain: [{ // 其他-短链
        name: "Generate short link",
        url: "/short-chain/generate",
    }, {
        name: "Query short link",
        url: "/short-chain/query",
    }],
    general: [{ // 其他-通用
        name: "Balance inquiry",
        url: "/general/balance-query",
    }],
};