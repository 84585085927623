<template>
  <main>
    <section class="container-fluid homeBanner textDark">
      <div class="container">
        <div class="row d-flex justify-content-center flex-md-reverse">
          <div class="col-12 col-sm-10 col-md-11 col-lg-10 col-xl-11">
            <div class="row row_sign_up">
              <div
                v-if="!sendSuccess"
                class="col-12 col-xl-6 col-md-12 d-flex col-sign-up"
              >
                <div class="inner">
                  <h1 class="hdng lg_size">Sign Up Now!</h1>
                  <p class="cntnt"></p>
                </div>

                <section class="contact_sec">
                  <div class="form_wrap">
                    <form id="contactForm">
                      <div class="row">
                        <div class="col-xl-6 col-lg-12">
                          <div class="form_field">
                            <label for="email"
                              >Company Email <span class="required">*</span>
                            </label>
                            <input
                              type="text"
                              class="form_control"
                              name="email"
                              id="email"
                              placeholder="e.g anthony@gmail.com"
                              v-model="signUpFormData.formData.email"
                              @input="checkUserEmail"
                              maxlength="100"
                            />
                            <div
                              v-if="!signUpFormData.errors.showErrorEmail"
                              class="errorInput"
                            >
                              Please Enter Company Email
                            </div>
                          </div>
                        </div>
                        <div class="col-xl-6 col-lg-12">
                          <SelectCountry
                            ref="selectCountry"
                            :isZh="false"
                            v-model:countryId="
                              signUpFormData.formData.countryId
                            "
                          />
                        </div>
                        <div class="col-xl-6 col-lg-12">
                          <div class="form_field">
                            <label
                              >Firstname <span class="required">*</span></label
                            >
                            <input
                              type="text"
                              class="form_control"
                              placeholder="Firstname"
                              v-model="signUpFormData.formData.firstName"
                              @input="checkUserFName"
                              maxlength="20"
                            />
                            <div
                              v-if="!signUpFormData.errors.showErrorFirstName"
                              class="errorInput"
                            >
                              Please Enter First Name
                            </div>
                          </div>
                        </div>
                        <div class="col-xl-6 col-lg-12">
                          <div class="form_field">
                            <label
                              >Lastname <span class="required">*</span></label
                            >
                            <input
                              type="text"
                              class="form_control"
                              placeholder="Lastname"
                              v-model="signUpFormData.formData.lastName"
                              @input="checkUserLName"
                              maxlength="20"
                            />
                            <div
                              v-if="!signUpFormData.errors.showErrorLastName"
                              class="errorInput"
                            >
                              Please Enter Last Name
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="form_field">
                            <label
                              >Company Name
                              <span class="required">*</span></label
                            >
                            <input
                              type="text"
                              class="form_control"
                              placeholder="Company Name"
                              v-model="signUpFormData.formData.companyName"
                              @input="checkUserCompName"
                              maxlength="1024"
                            />
                            <div
                              v-if="!signUpFormData.errors.showErrorCompanyName"
                              class="errorInput"
                            >
                              Please Enter Company Name
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="form_field">
                            <label
                              for="check_privacy_policy"
                              class="checkbox_wrap"
                            >
                              <input
                                type="checkbox"
                                name="check_privacy_policy"
                                id="check_privacy_policy"
                                value="accepted"
                                v-model="termsCheck"
                              />
                              <span class="check_text">
                                By continuing, I acknowledge and agree to abide
                                by
                                <router-link to="/terms-services">
                                  <strong>NXCLOUD Terms of Service</strong>
                                </router-link>
                                and
                                <router-link to="/privacy-policy">
                                  <strong>NXCLOUD Privacy Policy.</strong>
                                </router-link>
                              </span>
                            </label>
                            <div
                              v-if="!signUpFormData.errors.showErrorTermsCheck"
                              class="errorInput"
                            >
                              Please Check this Field
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="submit_wrap">
                            <div class="submitWrapper signUp">
                              <input
                                type="submit"
                                value="Sign Up"
                                @click="submitSubLoginForm"
                              />
                              <!-- Loader -->
                              <div
                                v-if="signUpFormData.errors.isLoginSubmit"
                                class="loader"
                              >
                                <div class="tm-ring">
                                  <div></div>
                                  <div></div>
                                  <div></div>
                                  <div></div>
                                </div>
                              </div>
                            </div>
                            <div
                              v-if="signUpFormData.errors.messageAfterSubmit"
                              :class="{
                                error: signUpFormData.errors.messageAfterSubmit
                              }"
                              class="messageAfterSubmit"
                            >
                              {{ signUpFormData.errors.messageAfterSubmit }}
                            </div>
                            <p class="info">
                              Already an existing user?
                              <router-link to="/login">
                                <u>Login here</u>
                              </router-link>
                            </p>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </section>
              </div>
              <div v-else class="col-12 col-xl-6 col-md-12 d-flex col-sign-up">
                <div class="inner">
                  <h1 class="hdng lg_size">Thank you for signing up!</h1>
                  <p class="cntnt"></p>
                </div>

                <div class="sign-up-success-card">
                  We have sent an activation email to
                  <span>{{ signUpFormData.formData.email }}</span
                  >. To continue, please check your email and verify your
                  account.
                  <br />
                  If you didn't see one, please check your spam/junk folders.
                </div>

                <span class="sign-up-tip-1"
                  >Did not receive the activation mail？</span
                >

                <div class="submitWrapper signUp" style="margin-top: 30px">
                  <input
                    class="submit-btn"
                    type="submit"
                    value="Resend"
                    @click="submitSubLoginForm"
                  />
                  <!-- Loader -->
                  <div
                    v-if="signUpFormData.errors.isLoginSubmit"
                    class="loader"
                  >
                    <div class="tm-ring">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-xl-6 col-md-12 mt-4 mt-md-0">
                <img src="../../assets/images/register_banner_2.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="cts">
      <img
        src="../../assets/images/footer-shape.png"
        alt=""
        class="ftr_shape"
      />
      <div class="container">
        <div class="row row-cts">
          <div class="col-md-8">
            <h3 class="archived-hdng cts-hdng">
              Start offering your customers a better service experience
            </h3>
          </div>

          <div class="col-md-4">
            <div class="cts-btn" @click="scrollTop">
              <router-link to="/sign-up" class="btn btn-success">
                Get Started
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import axios from "axios";
import util from "@/libs/util.js";
import SelectCountry from "../components/selectCountry.vue";
import Cookies from "cookies-js";

export default {
  name: "SignupPage",
  components: { SelectCountry },
  data() {
    return {
      sendSuccess: false,
      termsCheck: [],
      signUpFormData: {
        errors: {
          showErrorEmail: true,
          showErrorFirstName: true,
          showErrorLastName: true,
          showErrorCompanyName: true,
          showErrorTermsCheck: true,
          isSignUpSubmit: false,
          messageAfterSubmit: ""
        },
        formData: {
          email: "",
          firstName: "",
          lastName: "",
          countryId: "",
          companyAddress: ""
        }
      }
    };
  },
  mounted() {
    console.log(this.$store.state);
  },
  methods: {
    checkUserEmail() {
      if (!this.signUpFormData.formData.email.trim()) {
        this.signUpFormData.errors.showErrorEmail = false;
      } else {
        this.signUpFormData.errors.showErrorEmail = true;
      }
    },
    checkUserFName() {
      if (!this.signUpFormData.formData.firstName.trim()) {
        this.signUpFormData.errors.showErrorFirstName = false;
      } else {
        this.signUpFormData.errors.showErrorFirstName = true;
      }
    },
    checkUserLName() {
      if (!this.signUpFormData.formData.lastName.trim()) {
        this.signUpFormData.errors.showErrorLastName = false;
      } else {
        this.signUpFormData.errors.showErrorLastName = true;
      }
    },
    checkUserCompName() {
      if (!this.signUpFormData.formData.companyName.trim()) {
        this.signUpFormData.errors.showErrorCompanyName = false;
      } else {
        this.signUpFormData.errors.showErrorCompanyName = true;
      }
    },
    submitSubLoginForm(event) {
      event.preventDefault();

      //  check if fields are empty
      if (!this.signUpFormData.formData.email.trim()) {
        this.signUpFormData.errors.showErrorEmail = false;
        return;
      }
      if (!this.signUpFormData.formData.countryId) {
        this.$refs.selectCountry.showErrorCountryM();
        return;
      }
      if (!this.signUpFormData.formData.firstName.trim()) {
        this.signUpFormData.errors.showErrorFirstName = false;
        return;
      }
      if (!this.signUpFormData.formData.lastName.trim()) {
        this.signUpFormData.errors.showErrorLastName = false;
        return;
      }
      // if (!this.signUpFormData.formData.companyAddress.trim()){
      //   this.signUpFormData.errors.showErrorCompanyName = false;
      //   return
      // }
      if (!this.termsCheck[0]) {
        this.signUpFormData.errors.showErrorTermsCheck = false;
        return;
      }

      this.signUpFormData.errors.isLoginSubmit = true;
      if (Cookies.get("resource")) {
        this.signUpFormData.formData.businessType = parseInt(
          Cookies.get("resource")
        );
      }

      // if(this.userFormData.formData.account != "" && this.userFormData.formData.password != ""){
      axios
        .post(
          `${util.didApi}/register/sendEmail`,
          this.signUpFormData.formData,
          {
            headers: {
              "Content-Language": "EN",
              "Content-Type": "application/json"
            }
          }
        )
        .then((response) => {
          console.log(response);
          this.signUpFormData.errors.isLoginSubmit = false;
          this.signUpFormData.errors.showErrorTermsCheck = true;
          if (response.data.code === 0) {
            // this.signUpFormData.errors.messageAfterSubmit = "success";
            this.sendSuccess = true;
            this.behaviorRecord();
          } else {
            this.signUpFormData.errors.messageAfterSubmit = response.data.msg;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    behaviorRecord() {
      const platform = require("platform");
      const nxAccount = this.signUpFormData.formData.email.replace("@", "%40%");
      // eslint-disable-next-line
      gtag("event", "提交注册", {
        user_id: nxAccount,
        nxAccount: nxAccount,
        nxEquipment: platform.os.family,
        nxSource: "英文官网",
        nxWhereFrom: this.$store.state.whereFrom
      });
    },
    scrollTop() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "instant"
      });
    }
  }
};
</script>

<style></style>
