<template>
  <footer class="main-footer">
    <div class="cookie_wrap" v-show="cookieShow">
      <div class="text">
        By clicking "Allow All", you agree to the storing of cookies on your device to enhance site navigation, analyze site usage, and assist in our marketing efforts. You can read more about our <router-link to="/cookie-policy">Cookie Policy</router-link>.
      </div>
      <div class="right">
        <span class="cookie_btn" @click="hiddleTips">Allow All</span>
        <span class="cookie_btn cookie_btn_cancel" @click="hiddleTips">Decline</span>
      </div>
    </div>
    <div class="chatbot_wrap" :class="{ active: isChatbot }">
      <button class="chatbot_btn" @click="toggleChatBox">
        <img src="../assets/images/Chat.svg" alt="chatbot" />
      </button>
      <ul class="list">
        <li>
          <a
            href="https://wa.me/message/O23CLSZF6HK7B1"
            target="_blank"
          >
            <i class="icon">
              <img src="../assets/images/whatsapp_bot.svg" alt="whatsapp_bot" />
            </i>
            <span class="text">Whatsapp</span>
          </a>
        </li>
        <li>
          <a href="https://join.skype.com/invite/wVm5vaQ810WR">
            <i class="icon">
              <img src="../assets/images/skype_bot.svg" alt="skype_bot"/>
            </i>
            <span class="text">skype</span>
          </a>
        </li>
        <li class="contact_sec chatbotFormPopup">
          <div
            class="chatbotFormPopupBox"
            :class="{ active: isChatFormContainer }"
          >
            <div class="form_wrap" :class="{ active: isChatFormWrap }">
              <h4 class="heading">Contact Us</h4>
              <div class="form_box">
                <form>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="input_wrap">
                        <label>Full Name <span class="required">*</span></label>
                        <input
                          type="text"
                          v-model="popupForm.formData.fullName"
                          placeholder="Full Name"
                          @input="checkContactName"
                          maxlength="50"
                        />
                        <div
                          v-if="!popupForm.errors.showErrorName"
                          class="errorInput"
                        >
                          Please Enter Name
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <div class="input_wrap">
                        <label>Email <span class="required">*</span></label>
                        <input
                          type="email"
                          placeholder="e.g anthony@gmail.com"
                          v-model="popupForm.formData.email"
                          @input="checkContactEmail"
                          maxlength="100"
                        />
                        <div
                          v-if="!popupForm.errors.showErrorEmail"
                          class="errorInput"
                        >
                          Please Enter A Valid Email Address
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <div class="input_wrap">
                        <label
                          >Phone number <span class="required">*</span></label
                        >
                        <input
                          type="text"
                          placeholder="+44 0000 0000"
                          v-model="popupForm.formData.phone"
                          @input="checkContactPhone"
                          maxlength="100"
                        />
                        <div
                          v-if="!popupForm.errors.showErrorPhone"
                          class="errorInput"
                        >
                          Please Enter A Valid Phone Number
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <div class="input_wrap">
                        <label>Message <span class="required">*</span></label>
                        <textarea
                          placeholder="Please briefly describe your needs and one of our dedicated consultants will contact you"
                          v-model="popupForm.formData.message"
                          @input="checkContactMessage"
                          maxlength="200"
                        >
                        </textarea>
                        <div
                          v-if="!popupForm.errors.showErrorMessage"
                          class="errorInput"
                        >
                          Please Enter Message
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <div class="options">
                        <div class="row">
                          <div class="col-md-12">
                            <p class="info danger">
                              * Alternatively, you may email to
                              <a href="mailto:info@nxcloud.com"
                                >info@nxcloud.com</a
                              >
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="popupForm.submitError"
                      class="errorInput"
                    >
                      {{ popupForm.submitError }}
                    </div>
                    <div class="col-md-12">
                      <div class="submit_wrap submitWrapper">
                        <input
                          type="submit"
                          value="Send Now"
                          class="btn"
                          @click="submitChatForm"
                        />
                        <!-- Loader -->
                        <div
                          v-if="popupForm.isContactSubmit"
                          class="loader"
                        >
                          <div class="tm-ring">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div
              class="thankyou_wrap"
              style="display: none"
              :class="{ active: isThankYou }"
            >
              <div class="icon_wrap">
                <img src="../assets/images/check-circle.svg" alt="check_icon" />
              </div>
              <div class="text_wrap">
                <h4>Thank you</h4>
                <p>
                  Your message have been sent
                </p>
                <a
                  href="javascript:void(0)"
                  class="btn done_btn"
                  @click="thankyouDone"
                  >Done</a
                >
              </div>
            </div>
            <button class="closePopup" @click="closeChatForm"></button>
          </div>
          <a
            href="javascript:void(0);"
            target="_blank"
            class="chatbot_icon"
            @click="toggleChatForm"
          >
            <i class="icon">
              <img src="../assets/images/email.svg" alt="email" />
            </i>
            <span class="text">Email</span>
          </a>
        </li>
      </ul>
    </div>
    <div class="container desktop">
      <div class="row">
        <div class="col-md-4">
          <router-link to="/">
            <img
              src="../assets/images/footer_logo.svg"
              alt="NXCLOUD"
              class="img-fluid"
            />
          </router-link>
          <div class="footer-icons-wrap">
            <div class="footer-icons">
              <a
                href="https://www.facebook.com/people/NXCLOUD/100063278852731/"
                target="_blank"
              >
                <img src="../assets/images/fb.svg" class="img-fluid" alt="fb"
              /></a>
            </div>
            <div class="footer-icons">
              <a
                href="https://www.linkedin.com/company/nxcloud/"
                target="_blank"
                ><img
                  src="../assets/images/li.svg"
                  class="img-fluid"
                  alt="linkdin"
              /></a>
            </div>
            <div class="footer-icons">
              <a href="https://twitter.com/NXCLOUD1" target="_blank">
                <img src="../assets/images/x.svg" class="img-fluid" alt="tw"
              /></a>
            </div>
            <div class="footer-icons">
              <a
                href="https://www.instagram.com/nxcloud_communication/"
                target="_blank"
                ><img
                  src="../assets/images/instagram-white-icon.svg"
                  class="img-fluid"
                  alt="en"
              /></a>
            </div>
          </div>
        </div>

        <div class="col-md-8">
          <div class="row">
            <div class="col-md-4">
              <div class="footer-list-wrap">
                <h5 class="footer-list-hdng">Communication</h5>
                <ul>
                  <li>
                    <router-link to="/product-message"> Message </router-link>
                  </li>
                  <li>
                    <router-link to="/product-voice"> Voice </router-link>
                  </li>
                  <li>
                    <router-link to="/product-conversation">
                      Conversation
                    </router-link>
                  </li>
                </ul>

                <h5 class="footer-list-hdng solution-hdng">Application</h5>
                <ul>
                  <li>
                    <router-link to="/product-fraud-prevention">
                      Fraud Prevention
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/product-global-number">
                      Global Number
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/product-customer-engagement">
                      Customer Engagement
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-md-4">
              <div class="footer-list-wrap">
                <h5 class="footer-list-hdng">Industry</h5>
                <ul>
                  <li>
                    <router-link to="/industry-e-commerce">
                      E-commerce
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/industry-education">
                      Education
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/industry-fintech"> Fintech </router-link>
                  </li>
                  <li>
                    <router-link to="/industry-gaming"> Gaming </router-link>
                  </li>
                  <li>
                    <router-link to="/industry-logistics">
                      Logistics
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/industry-online-social">
                      Online Social
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/industry-retail"> Retail </router-link>
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-md-4">
              <div class="footer-list-wrap">
                <h5 class="footer-list-hdng">Company</h5>
                <ul>
                  <li>
                    <router-link to="/company"> About </router-link>
                  </li>
                  <li>
                    <router-link to="/terms-services">
                      Terms of Service
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/privacy-policy">
                      Privacy Policy
                    </router-link>
                  </li>
                  <li>
                    <a href="javascript:void(0);" @click="openCookieSetting">
                      Cookie Settings
                    </a>
                  </li>
                  <!-- <li>
                    <a href="/news">News</a>
                  </li> -->
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container mobile">
      <div class="row">
        <div class="col-12">
          <div class="footer_logo_wrap">
            <router-link to="/">
              <img
                src="../assets/images/footer_logo.svg"
                alt="NXCLOUD"
                class="img-fluid"
              />
            </router-link>
          </div>
        </div>

        <div class="col-md-12">
          <div class="row">
            <div class="col-md-6">
              <div class="footer-list-wrap">
                <h5 class="footer-list-hdng">Communication</h5>
                <ul>
                  <li>
                    <router-link to="/product-message"> Message </router-link>
                  </li>
                  <li>
                    <router-link to="/product-voice"> Voice </router-link>
                  </li>
                  <li>
                    <router-link to="/product-conversation">
                      Conversation
                    </router-link>
                  </li>
                </ul>

                <h5 class="footer-list-hdng">Industry</h5>
                <ul>
                  <li>
                    <router-link to="/industry-e-commerce">
                      E-commerce
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/industry-education">
                      Education
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/industry-fintech"> Fintech </router-link>
                  </li>
                  <li>
                    <router-link to="/industry-gaming"> Gaming </router-link>
                  </li>
                  <li>
                    <router-link to="/industry-logistics">
                      Logistics
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/industry-online-social">
                      Online Social
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/industry-retail"> Retail </router-link>
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-md-6">
              <div class="footer-list-wrap">
                <h5 class="footer-list-hdng">Application</h5>
                <ul>
                  <li>
                    <router-link to="/product-fraud-prevention">
                      Fraud Prevention
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/product-global-number">
                      Global Number
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/product-customer-engagement">
                      Customer Engagement
                    </router-link>
                  </li>
                </ul>
                <h5 class="footer-list-hdng">Company</h5>
                <ul>
                  <li>
                    <router-link to="/company"> About </router-link>
                  </li>
                  <li>
                    <router-link to="/terms-services">
                      Service Agreement
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/privacy-policy">
                      Privacy Policy
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12">
          <div class="footer-icons-wrap">
            <div class="footer-icons">
              <a
                href="https://www.facebook.com/people/NXCLOUD/100063278852731/"
                target="_blank"
              >
                <img src="../assets/images/fb.svg" class="img-fluid" alt="fb"
              /></a>
            </div>
            <div class="footer-icons">
              <a
                href="https://www.linkedin.com/company/nxcloud/"
                target="_blank"
                ><img
                  src="../assets/images/li.svg"
                  class="img-fluid"
                  alt="linkdin"
              /></a>
            </div>
            <div class="footer-icons">
              <a href="https://twitter.com/NXCLOUD1" target="_blank">
                <img src="../assets/images/x.svg" class="img-fluid" alt="tw"
              /></a>
            </div>
            <div class="footer-icons">
              <a
                href="https://www.instagram.com/nxcloud_communication/"
                target="_blank"
                ><img
                  src="../assets/images/instagram-white-icon.svg"
                  class="img-fluid"
                  alt="en"
              /></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Icp />
  </footer>
</template>

<script>
import axios from "axios";
import Icp from "./components/icp.vue";
import util from "@/libs/util.js";
export default {
  name: "FooterEng",
  components: {
    Icp,
  },
  data() {
    return {
      changeTabFromUrl: "",
      isChatbot: false,
      isChatFormContainer: false,
      isChatFormWrap: false,
      isThankYou: false,
      cookieShow: true,
      popupForm: {
        errors: {
          showErrorName: true,
          showErrorEmail: true,
          showErrorPhone: true,
          showErrorMessage: true,
        },
        isContactSubmit: false,
        submitError: false,
        formData: {
          fullName: "",
          email: "",
          phone: "",
          message: "",
        },
      },
    };
  },
  mounted() {
    this.getIdfromUrl();
    this.$router.afterEach(() => {
      this.getIdfromUrl();
    });
    if (localStorage.getItem("nx_cookies")) {
      this.cookieShow = false;
    }
  },

  methods: {
    // changeTab function
    changeTab() {
      console.log("changeTabFromUrl " + this.changeTabFromUrl);
      const tabBtn = `[data-bs-target="#${this.changeTabFromUrl}"]`;
      console.log(tabBtn);
      setTimeout(() => {
        const targetElement = document.querySelector(tabBtn);
        if (targetElement) {
          targetElement.click();
          document.querySelector(".prod_desc_section").scrollIntoView({
            behavior: "instant",
          });
        } else {
          console.error(`Element with selector ${tabBtn} not found.`);
        }
      }, 100);
    },
    openCookieSetting() {
      localStorage.removeItem("nx_cookies");
      this.cookieShow = true;
    },
    // getIdfromUrl for active tab on change
    getIdfromUrl() {
      // Pura URL le lena ke bajay sirf query string le lena.
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      // ID parameter ko extract karna.
      const id = urlParams.get("id");
      if (id) {
        this.changeTabFromUrl = id;
        this.changeTab();
      }
    },
    // Toggle Chatbot
    toggleChatBox(event) {
      event.preventDefault();
      if (!this.isChatbot) {
        this.isChatbot = true;
      } else {
        this.isChatbot = false;
        this.isChatFormContainer = false;
      }
    },
    // Toggle Chatbot Form
    toggleChatForm(event) {
      event.preventDefault();
      if (!this.isChatFormContainer) {
        this.isChatFormContainer = true;
        this.isChatFormWrap = true;
      } else {
        this.isChatFormContainer = false;
        this.isChatFormWrap = false;
      }
    },
    // Close chatbot form
    closeChatForm(event) {
      event.preventDefault();
      if (this.isChatFormContainer) {
        this.isChatFormContainer = false;
        this.isThankYou = false;
      }
    },
    // Thankyou done
    thankyouDone(event) {
      event.preventDefault();
      this.isChatbot = false;
      this.isChatFormContainer = false;
      this.isChatFormWrap = false;
      this.isThankYou = false;
    },
    checkContactName() {
      this.popupForm.submitError = "";
      if (!this.popupForm.formData.fullName.trim()) {
        this.popupForm.errors.showErrorName = false;
      } else {
        this.popupForm.errors.showErrorName = true;
      }
    },
    checkContactEmail() {
      this.popupForm.submitError = "";
      const value = this.popupForm.formData.email.trim();
      const reg = /^([A-Z0-9a-z._%+-]+)@([a-z0-9A-Z]+([-]+[a-z0-9A-Z]+)?\.)+[a-zA-Z]{1,}$/;
      if (!value || !reg.test(value)) {
        this.popupForm.errors.showErrorEmail = false;
      } else {
        this.popupForm.errors.showErrorEmail = true;
      }
    },
    checkContactPhone() {
      this.popupForm.submitError = "";
      const value = this.popupForm.formData.phone.trim();
      const reg = /^[^A-Za-z\u4E00-\u9FFF]+$/; // 不能是中文或字母
      if (!value || value.length < 6 || !reg.test(value)) {
        this.popupForm.errors.showErrorPhone = false;
      } else {
        this.popupForm.errors.showErrorPhone = true;
      }
    },
    checkContactMessage() {
      this.popupForm.submitError = "";
      if (!this.popupForm.formData.message.trim()) {
        this.popupForm.errors.showErrorMessage = false;
      } else {
        this.popupForm.errors.showErrorMessage = true;
      }
    },
    submitChatForm(event) {
      event.preventDefault();

      //  check if fields are empty
      this.checkContactName();
      this.checkContactMessage();
      this.checkContactEmail();
      this.checkContactPhone();
      let result = false;
      Object.keys(this.popupForm.formData).forEach(item => {
        if (!this.popupForm.formData[item]) {
          result = true;
        }
      })
      if (result) {
        return;
      }
      this.popupForm.isContactSubmit = true;
      // if(this.userFormData.formData.account != "" && this.userFormData.formData.password != ""){
      axios
        .post(
          `${util.didApi}/register/officialContactUs`,
          this.popupForm.formData,
          {
            headers: {
              "Content-Language": "EN",
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          console.log(response);
          this.popupForm.isContactSubmit = false;
          if (response.data.code === 0) {
            if (!this.isThankYou) {
              this.isThankYou = true;
              this.isChatFormWrap = false;
              this.popupForm.formData = { // 清空表单数据
                fullName: "",
                email: "",
                phone: "",
                message: "",
              };
            }
          } else {
            this.popupForm.submitError = response.data.msg;
          }
          // this.popupForm.errors.messageAfterSubmit = response.data.msg;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    hiddleTips() {
      localStorage.setItem("nx_cookies", '1');
      this.cookieShow = false;
    },
  },
};
</script>

<style scoped>
.contact_sec .form_wrap {
  display: none;
}

.contact_sec .form_wrap.active {
  display: block !important;
}

.chatbot_wrap
  .list
  li.chatbotFormPopup
  .chatbotFormPopupBox
  .thankyou_wrap.active {
  display: block !important;
}
</style>
